export default {
  public: {
    title: "Web pública",
    subtitle: "Configura aquí tu web pública",
    generalForm: {
      title: "General",
      publicate: "Publicar web",
      status: {
        label: "Estado de la publicación",
        options: {
          enabled: "Activado",
          disabled: "Desactivado"
        }
      },
      seeWeb: "Ver web",
      url: "URL de tu portal",
      powered: {
        title: 'Quitar "Powered by Lázzaro" del pie de página',
        under: "Se mostrará el mensaje al final de la web",
        options: {
          enabled: "Activado",
          disabled: "Desactivado"
        }
      }
    },
    projectsForm: {
      title: "Proyectos",
      backgroundColor: "Color de fondo",
      sectionTitle: {
        es: "Titulo (Español)",
        en: "Titulo (Inglés)"
      },
      sectionTitleColor: "Color del título",
      sectionSubtitle: {
        es: "Subtítulo (Español)",
        en: "Subtítulo (Inglés)"
      },
      sectionSubtitleColor: "Color del subtítulo"
    },
    landingSections: {
      projects: "Proyectos",
      formation: "Formaciones",
      events: "Eventos",
      shop: "Tienda",
      collaborate: "Colaborar"
    },
    logosForm: {
      title: "Logos",
      subtitle:
        "Hemos dejado este espacio para que añadas logos de cosas chulas",
      logo: {
        label: "Logotipo",
        help: "180x60, máx 25MB",
        limit: "No puedes agregar más de 10 logos",
        delete: "Logotipo eliminado correctamente",
        error: "Error al eliminar el logotipo"
      }
    },
    chooseTemplate: {
      title: "Plantilla",
      template1: "Elegir plantilla 1",
      template2: "Elegir plantilla 2",
      template3: "Elegir plantilla 3",
      modalText: "elige una plantilla",
      templateChangeModal: {
        title: "La plantilla está siendo cambiada.",
        subtitle:
          "¡Genial, vas a ver la nueva plantilla! esto puede tardar unos minutos. Es el momento perfecto para preparar el café."
      }
    },

    googleAnalytics: {
      title: "ID Google Analytics"
    },

    websiteStatus: {
      publishWebsite: {
        title: "Estamos publicando tu web, esto puede tardar hasta 5 minutos.",
        subtitle:
          'Recuerda que puedes volver a ocultarla otra vez cuando quieras pulsando el mismo botón y dándole a "guardar"'
      },

      unpublishWebsite: {
        title: "Estamos ocultando tu web, esto puede tardar hasta 5 minutos.",
        subtitle:
          "Recuerda que puedes volver a publicarla cuando quieras clicando el mismo botón y después dándole al botón “guardar”."
      }
    },
    brandForm: {
      title: "Marca",
      logo: {
        label: "Logotipo",
        help: "180x60, máx 25MB"
      },
      mainImg: {
        label: "Imagen principal",
        help: "180x60, máx 25MB"
      },
      subLogo: {
        label: "Sublogo",
        help: "180x60, máx 25MB",
        button: "Añadir imagen"
      },
      loadingIcon: {
        label: "Icono de carga",
        help: "180x60, máx 25MB",
        button: "Añadir icono de carga"
      },
      orgName: "Nombre de la organización",
      colour: {
        first: "Color destacado 1",
        second: "Color destacado 2",
        footer: "Color del footer",
        background: "Color de fondo"
      }
    },
    contactForm: {
      title: "Contacto",
      phone: "Teléfono",
      location: "Ubicación",
      backgroundColour: "Color de fondo"
    },
    contentForm: {
      title: "Contenido",
      subtitle:
        "Selecciona qué secciones quieres que aparezcan en tu web pública.",
      sectionStatus: {
        title: "Estado de la sección",
        options: {
          enabled: "Activado",
          disabled: "Desactivado"
        }
      },
      projects: "Proyectos",
      donations: "Donaciones",
      partners: "Socios",
      market: "Tienda online",
      courses: "Cursos",
      impact: "Datos de impacto",
      events: "Eventos",
      volunteers: "Voluntarios",
      designButton: "Diseño"
    },
    socialForm: {
      title: "Redes sociales",
      facebook: "Link de Facebook",
      linkedin: "Link de LinkedIn",
      instagram: "Link de Instagram",
      twitter: "Link de Twitter",
      web: "Link web"
    },
    termsForm: {
      title: "Términos y condiciones, aviso legal y política de privacidad",
      add: "Seleccionar archivos",
      terms: {
        es:
          "Sube tus términos y condiciones, aviso legal y política de privacidad para que tus usuarios las acepten (Español)",
        en:
          "Sube tus términos y condiciones, aviso legal y política de privacidad para que tus usuarios las acepten (Inglés)"
      },

      transparencyDescription: {
        es: "Descripción de transparencia (Español)",
        en: "Descripción de transparencia (Inglés)"
      },
      accountability: "Transparencia y rendición de cuentas"
    },
    teamForm: {
      title: "Equipo",
      subtitle:
        "Configura y crea uno a uno cada miembro de tu equipo. Puedes verlos todos en el listado de abajo.",
      photo: {
        label: "Foto de perfil",
        help: "180x60, máx 25MB"
      },
      name: "Nombre y apellidos",
      position: "Cargo",
      linkedin: "Link a LinkedIn",
      newMember: "Añadir miembro",
      table: "Tu equipo",
      delete: ""
    },
    descriptionForm: {
      title: "Descripción",
      subtitle:
        "Acompaña la web de una descripción de tu organización, de tu visión, de tu misión y de tus valores.",
      motto: {
        label: {
          es: "Lema de la organización (Español)",
          en: "Lema de la organización (Inglés)"
        },
        help: "Máx. 40 caracteres."
      },
      description: {
        es: "Descripción corta de la organización (Español)",
        en: "Descripción corta de la organización (Inglés)"
      },
      titleDescription: {
        label: {
          en: "Título de la descripción (Inglés)",
          es: "Título de la descripción (Español)"
        },
        help: "Máx. 40 caracteres."
      },
      secondaryImg: {
        label: "Imagen secundaria",
        help: "180x60, máx 25MB"
      },
      largeDescription: {
        es: "Descripción larga (Español)",
        en: "Descripción larga (Inglés)"
      }
    },
    impactForm: {
      title: "Datos de impacto",
      subtitle: "Modifica los datos de impacto visibles en la web.",
      text: {
        es: "Texto impacto {n} (Español)",
        en: "Texto impacto {n} (Inglés)"
      },
      amount: {
        one: "Cantidad impacto 1",
        two: "Cantidad impacto 2",
        three: "Cantidad impacto 3",
        four: "Cantidad impacto 4"
      }
    },
    notify: {
      success: "Cambios guardados con éxito",
      error: "Ha ocurrido un error al guardar"
    },
    modalDesign: {
      title: "Diseño",
      projects: {
        title: "Proyectos",
        backgroundColor: "Color de fondo"
      },
      members: {
        title: "Miembros",
        backgroundColor: "Color de fondo"
      },
      courses: {
        title: "Cursos",
        backgroundColor: "Color de fondo"
      },
      hero: {
        title: "Hero",
        backgroundColor: "Color de fondo"
      },
      shop: {
        title: "Tienda",
        backgroundColor: "Color de fondo"
      },
      aboutUs: {
        title: "Sobre nosotros",
        backgroundColor: "Color de fondo"
      },
      events: {
        title: "Eventos",
        backgroundColor: "Color de fondo"
      },
      volunteers: {
        title: "Voluntarios",
        backgroundColor: "Color de fondo"
      },
      logo: {
        title: "Logo",
        backgroundColor: "Color de fondo"
      }
    }
  }
};
