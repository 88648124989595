export default {
  public: {
    title: "Public website",
    subtitle: "Set up here your public website",
    generalForm: {
      title: "General",
      publicate: "Publish website",
      status: {
        label: "Publication status",
        options: {
          enabled: "Enabled",
          disabled: "Disabled"
        }
      },
      seeWeb: "View website",
      url: "URL",
      powered: {
        title: 'Remove "Powered by Lázzaro" from footer',
        under: "The message will appear at the bottom of your website",
        options: {
          enabled: "Enabled",
          disabled: "Disabled"
        }
      }
    },
    projectsForm: {
      title: "Projects",
      backgroundColor: "Background Color",
      sectionTitle: "Section Title",
      sectionSubtitle: "Section Subtitle"
    },
    landingSections: {
      projects: "Projects",
      formation: "Formations",
      events: "Events",
      shop: "Shop"
    },
    logosForm: {
      title: "Logos",
      subtitle: "We have left this space for you to add logos of cool things",
      logo: {
        label: "Logo",
        help: "180x60, max 25MB",
        limit: "You can not add more than 10 logos",
        delete: "logo Deleted successfully",
        error: "Error deleting logo"
      }
    },

    chooseTemplate: {
      title: "Template",
      template1: "Choose template 1",
      template2: "Choose template 2",
      template3: "Choose template 3",
      modalText: "Choose a template",
      templateChangeModal: {
        title: "The template is being changed",
        subtitle:
          "Cool you are gonna see the new template! this might take few minutes. It is the perfect moment to prepare the coffee"
      }
    },

    googleAnalytics: {
      title: "Google analytics ID"
    },

    websiteStatus: {
      publishWebsite: {
        title: "We are publishing your website this can take up to 5 minutes.",
        subtitle:
          "Remember you can unpublish it again whenever you one clicking the same button and saving the changes in the “save”. "
      },

      unpublishWebsite: {
        title:
          "We are unpublishing your website this can take up to 5 minutes.",
        subtitle:
          "Remember you can publish it again whenever you one clicking the same button and saving the changes in the “save”. "
      }
    },

    brandForm: {
      title: "Appearance",
      logo: {
        label: "Logo",
        help: "180x60, max 25MB"
      },
      mainImg: {
        label: "Primary image",
        help: "180x60, max 25MB"
      },
      subLogo: {
        label: "SubLogo",
        help: "180x60, max 25MB",
        button: "Add image "
      },
      loadingIcon: {
        label: "Loading Icon",
        help: "180x60, max 25MB",
        button: "Add loading icon"
      },
      orgName: "Company name",
      colour: {
        first: "Theme colour 1",
        second: "Theme colour 2",
        footer: "Footer Color",
        background: "Background"
      }
    },
    contactForm: {
      title: "Contact details",
      phone: "Phone no.",
      location: "Location",
      backgroundColour: "Background color"
    },
    contentForm: {
      title: "Content",
      subtitle: "Choose what sections will appear in your public website.",
      sectionStatus: {
        title: "Selection status",
        options: {
          enabled: "Enabled",
          disabled: "Disabled"
        }
      },
      projects: "Projects",
      donations: "Donators",
      partners: "Members",
      market: "Shop",
      courses: "Courses",
      impact: "Information on impact",
      events: "Events",
      volunteers: "Volunteers",
      designButton: "Design"
    },
    socialForm: {
      title: "Social media",
      facebook: "Facebook",
      linkedin: "LinkedIn",
      instagram: "Instagram",
      twitter: "Twitter",
      web: "Website URL"
    },
    termsForm: {
      title: "Terms and conditions, legal notice and privacy policy",
      add: "Choose files",
      terms:
        "Upload your terms and conditions, legal notice and privacy policy so that users can accept them",
      transparencyDescription: "Transparency description",
      accountability: "Transparency and accountability"
    },
    teamForm: {
      title: "Team",
      subtitle:
        "Set up one by one each of your members' profile. You can see them all down below.",
      photo: {
        label: "Profile picture",
        help: "180x60, max 25MB"
      },
      name: "Surname and first name",
      position: "Position",
      linkedin: "LinkedIn",
      newMember: "Add member",
      table: "Your team",
      delete: ""
    },
    descriptionForm: {
      title: "Description",
      subtitle:
        "Describe your organisation and your vision, mission and values.",
      motto: {
        label: "Motto",
        help: "Max. 40 characters."
      },
      description: "Brief description of your organisation",
      titleDescription: {
        label: "Description title",
        help: "Max. 40 characters."
      },
      secondaryImg: {
        label: "Secondary image",
        help: "180x60, max 25MB"
      },
      largeDescription: "Full description"
    },
    impactForm: {
      title: "Information on impact",
      subtitle: "Modify the impact data visible on the website.",
      text: {
        one: "Text impact 1",
        two: "Text impact 2",
        three: "Text impact 3",
        four: "Text impact 4"
      },
      amount: {
        one: "Amount impact 1",
        two: "Amount impact 2",
        three: "Amount impact 3",
        four: "Amount impact 4"
      }
    },
    notify: {
      success: "Changes successfully saved",
      error: "An error occurred while saving"
    },
    modalDesign: {
      title: "Design",
      projects: {
        title: "Projects",
        backgroundColor: "background color"
      },
      members: {
        title: "Members",
        backgroundColor: "background color"
      },
      courses: {
        title: "Courses",
        backgroundColor: "background color"
      },
      hero: {
        title: "Hero",
        backgroundColor: "background color"
      },
      shop: {
        title: "Shop",
        backgroundColor: "background color"
      },
      aboutUs: {
        title: "About Us",
        backgroundColor: "background color"
      },
      events: {
        title: "Events",
        backgroundColor: "background color"
      },
      volunteers: {
        title: "Volunteers",
        backgroundColor: "background color"
      },
      logo: {
        title: "Logo",
        backgroundColor: "Color de fondo"
      }
    }
  }
};
